<template>
  <CStack d="flex" direction="row" :mt="mt">
    <!-- <CBox bg="bgSocialBadge" borderRadius="full" w="40px" h="40px">
      <CBox w="inherit" h="inherit" d="table-cell" verticalAlign="middle">
        <CImage :src="require('@/assets/icon/facebook.png')" w="18px" h="18px" mx="auto" />
      </CBox>
    </CBox>
    <CBox bg="bgSocialBadge" borderRadius="full" w="40px" h="40px">
      <CBox w="inherit" h="inherit" d="table-cell" verticalAlign="middle">
        <CImage :src="require('@/assets/icon/instagram.png')" w="18px" h="18px" mx="auto" />
      </CBox>
    </CBox>
    <CBox bg="bgSocialBadge" borderRadius="full" w="40px" h="40px">
      <CBox w="inherit" h="inherit" d="table-cell" verticalAlign="middle">
        <CImage :src="require('@/assets/icon/twitter.png')" w="18px" h="18px" mx="auto" />
      </CBox>
    </CBox>
    <CBox bg="bgSocialBadge" borderRadius="full" w="40px" h="40px">
      <CBox w="inherit" h="inherit" d="table-cell" verticalAlign="middle">
        <CImage :src="require('@/assets/icon/whatsapp.png')" w="18px" h="18px" mx="auto" />
      </CBox>
    </CBox> -->
    <CBox d="flex" bg="bgSocialBadge" borders="1px" borderRadius="25px" w="130px" h="40px" mr="10px" class="pointer" @click="setClipboard(webcv_url+'/'+currentUser.emp_username)">
      <CBox mx="auto" h="inherit">
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage :src="require('@/assets/icon/copy.png')" w="18px" h="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="10px">
            <CText color="fontMain" fontSize="14px">Copy URL</CText>
          </CBox>
        </CStack>
      </CBox>
    </CBox>
  </CStack>
</template>

<script>
import config from "@/ConfigProvider";

export default {
  props: {
    currentUser: Object,
    mt: {
      type: String,
      default: "30px"
    }
  },
  data() {
    return {
      webcv_url: config.value("WEBCV_URL")
    }
  },
  methods: {
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: 'Copied',
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: false
      })
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
